import React, { useEffect, useState, useCallback, useRef, Fragment } from 'react';
import arxs from 'infra/arxs';
import GlobalContext from 'infra/GlobalContext';
import { OriginModuleEnum } from 'infra/api/contracts';
import { Spinner } from 'components/animations/Spinner';
import { createInputPopup } from 'components/shell/InputPopup/InputPopup';

import './ReportPreviewPopup.scss';

export interface ReportToPreview {
  id: string;
  module: OriginModuleEnum;
  uniqueNumber: string;
  url?: string;
  reportAlias: string;
}

export interface ReportPreviewProp {
  reports: ReportToPreview[];
  onConfirm: (ids: string[]) => void;
  title: string;
  confirmation: string;
}

export const createReportPreviewPopup = (props: ReportPreviewProp) => {
  const state: any = {
    title: <Fragment><i className="far fa-file-alt"></i> {props.title}</Fragment>,
    content: <ReportPreviewPopup {...props} />,
    maximized: true,
  };

  return state;
};

export function ReportPreviewPopup(props: ReportPreviewProp) {
  const initialLookups = {
    reportsByAliasMap: {} as { [key: string]: any },
  };

  type LookupsType = {
    reportsByAliasMap: { [key: string]: any };
  };

  const [lookups, setLookups] = useState<LookupsType>(initialLookups);
  const [index, setIndex] = useState(0);
  const [report, setReport] = useState<ReportToPreview | false>();
  const [renderedReports, setRenderedReports] = useState<ReportToPreview[]>([]);
  const [navigationActions, setNavigationActions] = useState<any[]>([]);

  const isFetching = useRef(false); // Ref to prevent multiple fetches

  // Subscribe to lookups once, no dependencies needed
  useEffect(() => {
    const subscription = arxs.Api.lookups.subscribe(lookups, (values: any) => {
      setLookups((prevLookups) => ({ ...prevLookups, ...values }));
    });

    return () => {
      subscription.dispose();
    };
  }, []);

  const updateOrAdd = (reports: ReportToPreview[], newReport: ReportToPreview) => {
    const existingIndex = reports.findIndex((r) => r.id === newReport.id);
    if (existingIndex !== -1 && reports[existingIndex].url !== newReport.url) {
      reports[existingIndex] = newReport;
    } else if (existingIndex === -1) {
      reports.push(newReport);
    }
  };

  const fetchReport = useCallback(async () => {
    if (isFetching.current || !props.reports[index] || !lookups.reportsByAliasMap) return;
    isFetching.current = true;

    const currentReport = props.reports[index];
    const existingReport = renderedReports.find((r) => r.id === currentReport.id);

    // Check if we already have the URL
    if (existingReport && existingReport.url) {
      setReport(existingReport);
      isFetching.current = false;
      return;
    }

    const templates = lookups.reportsByAliasMap[currentReport.reportAlias];
    if (!templates) {
      isFetching.current = false;
      return;
    }

    const template = templates[0];

    try {
      const result = await arxs
        .ReportClient.reporting()
        .generatePDF(
          { template, filters: [{ refs: [{ module: currentReport.module, objectId: currentReport.id }] }] },
          currentReport.reportAlias,
          '',
          true
        );

      if (typeof result === 'string') {
        const newReport = { ...currentReport, url: result };
        setRenderedReports((prev) => {
          const updatedReports = [...prev];
          updateOrAdd(updatedReports, newReport);
          return updatedReports;
        });
        setReport(newReport);
      } else {
        setReport(false);
      }
    } catch (error) {
      setReport(false);
    } finally {
      isFetching.current = false;
    }
  }, [index, lookups, props.reports, renderedReports]);

  // Trigger fetching of reports only when necessary
  useEffect(() => {
    fetchReport();
  }, [fetchReport]);

  // Update navigation actions when index or rendered reports change
  useEffect(() => {
    if (props.reports.length > 0) {
      setNavigationActions([
        {
          key: 'first',
          name: arxs.t('controls.report_preview_popup.nav.first'),
          icon: 'fas fa-angle-double-left',
          pre: true,
          isActive: index > 0,
          handle: () => setIndex(0),
        },
        {
          key: 'previous',
          name: arxs.t('controls.report_preview_popup.nav.previous'),
          icon: 'fas fa-angle-left',
          pre: true,
          isActive: index > 0,
          handle: () => setIndex(Math.max(index - 1, 0)),
        },
        {
          key: 'current',
          name: `${props.reports[index]?.uniqueNumber} (${index + 1}/${props.reports.length})`,
        },
        {
          key: 'next',
          name: arxs.t('controls.report_preview_popup.nav.next'),
          icon: 'fas fa-angle-right',
          isActive: index < props.reports.length - 1,
          handle: () => setIndex(Math.min(index + 1, props.reports.length - 1)),
        },
        {
          key: 'last',
          name: arxs.t('controls.report_preview_popup.nav.last'),
          icon: 'fas fa-angle-double-right',
          isActive: index < props.reports.length - 1,
          handle: () => setIndex(props.reports.length - 1),
        },
      ]);
    }
  }, [index, props.reports]);

  const confirm = useCallback(
    (context: any) => {
      const label = props.confirmation;
      const confirmation = createInputPopup(context, label, () => {
        props.onConfirm(props.reports.map((x) => x.id));
      });
      context.inputPopup.show(confirmation);
    },
    [props.reports, props.onConfirm]
  );

  return (
    <GlobalContext.Consumer>
      {(context) => (
        <div className="report-preview-popup">
          {props.reports.length > 1 && (
            <div className="report-preview-popup-nav">
              {navigationActions.map((action) => (
                <div
                  key={action.key}
                  className={[
                    action.handle ? 'report-preview-popup-nav-button' : 'report-preview-popup-nav-section',
                    action.isActive ? '' : 'inactive',
                  ].join(' ')}
                  onClick={action.isActive ? action.handle : undefined}
                >
                  {action.pre && <i className={action.icon}></i>}
                  {action.name}
                  {!action.pre && <i className={action.icon}></i>}
                </div>
              ))}
            </div>
          )}
          <div className="report-preview-popup-body">
            {!report || !report.url || report.url === "" ? <Spinner /> : <iframe src={report.url} title={report.uniqueNumber} />}
          </div>
          <div className="report-preview-popup-actions">
            {document && (
              <div className="report-preview-popup-actions-button confirm" onClick={() => confirm(context)}>
                {arxs.t('controls.report_preview_popup.confirm')}
              </div>
            )}
          </div>
        </div>
      )}
    </GlobalContext.Consumer>
  );
}
